// pre_flight_form_controller

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["individuals"];

  hideCustom() {
    this.individualsTarget.style.display = "none";
  }

  showCustom() {
    this.individualsTarget.style.display = "initial";
  }
}
