import "./digital-interview.pcss";
import createChannel from "channels/cable";

if (document.getElementById("digital-interview") != null) {
  createChannel(
    {
      channel: "CommandHistoryChannel",
      digital_interview_id: document.getElementById("digital-interview").dataset
        .di_id,
    },
    {
      connected() {},

      received(data) {
        if (data.head) {
          window.location.reload();
        } else {
          const historyElement = document.getElementById(data.instance_id);
          if (historyElement) {
            historyElement.innerHTML = data.html;
          }
        }
      },
    }
  );
}
