// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "init";
import "components/page/page";
import "components/auth-form/auth-form";
import "components/header/header";
import "components/digital-interviews/digital-interviews";
import "components/digital-interview/digital-interview";
import "components/invoiceables/invoiceables";
import "components/digital-interview-new/digital-interview-new";
import "components/pre-flight-command-form/pre-flight-command-form";
import "components/vna/vna";
import Rails from "@rails/ujs";

Rails.start();

// require("@rails/ujs").start();
require("turbolinks").start();
// require("@rails/activestorage").start()
// require("channels")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
