// starting file for application.
import "./index.pcss";

// an "evil" frontend inititates a stimulus controller
// the stimulus onConnect method starts up an Action Cable channel
// the server side subscribed method starts an ActiveJob to get the results from an external API
// When the job is performed, it calls ActionCable to send the list back to the client
// the ActionCable received method that is defined inside the stimulus controller updates the options list on the page
// main inspiration: https://evilmartians.com/chronicles/evil-front-part-2 (and 1 and 3)
// example of integration of stimulus, action cable and active job is found here:
// https://onrails.blog/2019/02/19/stimulus-actioncable-activejob-loading-asynchronous-api-data/

// Default install of stimulus is below

// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";

const application = Application.start();
const context = require.context("controllers", true, /_controller\.js$/);
application.load(definitionsFromContext(context));
