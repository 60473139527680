// frontend/client/cable.js
// example lifted from EvilMartians: https://evilmartians.com/chronicles/evil-front-part-1

import cable from "actioncable";

let consumer;

// consumer singleton
const createChannel = (...args) => {
  if (!consumer) {
    consumer = cable.createConsumer();
  }

  return consumer.subscriptions.create(...args);
};

export default createChannel;
