// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// Stimulus is initiated in the init/index.js
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus";
import createChannel from "channels/cable";

// when the page connects with this controller it sets up the Channel
// example from https://onrails.blog/2019/02/19/stimulus-actioncable-activejob-loading-asynchronous-api-data/
// by john beaty
export default class extends Controller {
  static targets = ["wpa_output", "cm_output"];

  connect() {
    const thisController = this;
    // channel for recent WPA projects
    createChannel(
      {
        channel: "NewDigitalInterviewChannel",
        request_id: this.data.get("request"),
      },
      {
        received(message) {
          if (message.key === "wpa")
            thisController.wpa_outputTarget.innerHTML = message.projects;
          else if (message.key === "cm")
            thisController.cm_outputTarget.innerHTML = message.surveys;
        },
      }
    );
  }
}
