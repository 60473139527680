import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["source1", "source2"];

  copy1() {
    this.source1Target.select();
    document.execCommand("copy");
  }

  copy2() {
    this.source2Target.select();
    document.execCommand("copy");
  }
}
